
import { Component, Vue } from 'vue-property-decorator';
import rulesService from '@/services/rules.service';
import DeleteDialog from '@/components/dialogs/delete-dialog.component.vue';

import AlertError from '@/components/alert-error/alert-error.component.vue';
import DatePicker from '@/components/date-picker/date-picker.component.vue';
import enterpriseService from '../../../Enterprises/services/enterprise.service';
import { PaymentCondition } from '../../entities/payment-condition.entity';
import paymentConditionService from '../../services/payment-condition.service';
import CompaniesService from '../../../Companies/services/companies.service';
import { Company } from '../../../Companies/entities/company.entity';
import { Enterprise } from '../../../Enterprises/entities/enterprise.entity';

@Component({ components: { AlertError, DatePicker, DeleteDialog } })
export default class PaymentConditionCreateComponent extends Vue {
  public $refs: any;

  private paymentCondition: PaymentCondition = new PaymentCondition();

  private showDialogDelete: boolean = false;

  private companies: Company[] = [];

  private errors: any[] = [];

  private enterprises: Enterprise[] = [];

  private status: any[] = [
    {
      id: 0,
      name: 'Inativo',
    },
    {
      id: 1,
      name: 'Ativo',
    },
  ];

  private percentageMask = {
    decimal: ',',
    thousands: '.',
    prefix: '',
    suffix: ' %',
    precision: 2,
  };

  private rules: any = {
    required: rulesService.required,
  };

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private get labelFormType() {
    return this.isEdit ? 'Editar' : 'Cadastrar';
  }

  private get isEdit() {
    const { id } = this.$route.params;
    return !!id;
  }

  private setShowDialogDelete(paymentCondition: PaymentCondition) {
    this.showDialogDelete = true;
  }

  private closeDialogDelete() {
    this.showDialogDelete = false;
  }

  private async getAllEnterprises() {
    if (!this.paymentCondition.companyId) return;
    const enterprises = await enterpriseService.getAllEnterprises({
      params: {
        company_id: this.paymentCondition.companyId,
        active: 1,
      },
    });
    this.enterprises = [];
    this.enterprises = enterprises.map((it: any) => new Enterprise(it));
  }

  private deleteItem() {
    this.paymentCondition
      .delete()
      .then(() => {
        this.$snackbar.open({
          text: 'Condição de Pagamento excluido com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.goToList();
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: err.response.data.message,
          color: 'danger',
          buttonColor: '#fff',
        });
        throw new Error(err);
      })
      .finally(() => {
        this.closeDialogDelete();
      });
  }

  private async getPaymentConditionById() {
    const { id } = this.$route.params;
    const paymentCondition = await paymentConditionService.getPaymentConditionById(id);
    this.paymentCondition = new PaymentCondition(paymentCondition);
    this.$loader.close();
  }

  private async submit() {
    if (!this.$refs.form.validate()) return;
    if (this.isEdit) {
      this.edit();
      return;
    }
    this.save();
  }

  private async save() {
    this.paymentCondition
      .save()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Condição de Pagamento cadastrado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'payment-condition-list',
        });
      })
      .catch((err: any) => {
        this.$snackbar.open({
          text: 'Erro ao cadastrar Condição de Pagamento',
          color: 'danger',
          buttonColor: '#fff',
        });
        this.errors = err.response.data.errors;
      });
  }

  private async edit() {
    this.paymentCondition
      .update()
      .then(() => {
        this.errors = [];
        this.$snackbar.open({
          text: 'Condição de Pagamento alterado com sucesso',
          color: 'success',
          buttonColor: '#fff',
        });
        this.$router.push({
          name: 'payment-condition-list',
        });
      })
      .catch((err: any) => {
        this.errors = err.response.data.errors;
      });
  }

  private async goToList() {
    this.$router.push({
      name: 'payment-condition-list',
    });
  }

  private async created() {
    if (this.isEdit) {
      this.getPaymentConditionById();
    }
    await this.getCompanies();
    await this.getAllEnterprises();
  }

  private async setCompanyId(companyId: string) {
    this.paymentCondition.companyId = companyId;
    this.getAllEnterprises();
  }

  private async getCompanies() {
    const companies: Company[] = await CompaniesService.getAllCompanies();
    this.companies = companies.map(company => new Company(company));
    if (this.companies.length > 0 && !this.paymentCondition.companyId) {
      this.paymentCondition.companyId = this.companies[0].id;
      this.getAllEnterprises();
    }
  }
}
