import { IEnterpriseRankingDefault } from '../components/form/interface/enterprise-ranking-users.interface';

export class EnterpriseRankingDefault implements IEnterpriseRankingDefault {
  public user_id: string;

  public user_name: string;

  public value: string;

  constructor(data: any = {}) {
    this.user_id = data.user_id || '';
    this.user_name = data.user_name || '';
    this.value = data.value || '';
  }
}
