import moment from 'moment-timezone';
import store from '@/store';
import { Address } from '@/components/address/entities/address.entity';
import Attachment from '@/modules/shared/entities/attachment.entity';
import { IAttachmentDefault } from '@/shared-modules/attachments-default/interfaces/attachment-default.interface';
import AttachmentDefault from '@/shared-modules/attachments-default/entities/attachment-default.entity';
import { PaymentCondition } from '../../PaymentConditions/entities/payment-condition.entity';
import enterpriseService from '../services/enterprise.service';
import { User } from '../../Users/entities/user.entity';
import { EnterpriseResults } from './enterprise-results.entity';
import { Block } from '../components/form/entities/block.entity';
import { Unit } from '../components/form/components/enterprise-units/units/entities/unit.entity';
import { EnterpriseAttachmentEnum } from '../components/form/enums/enterprise-attachment.enum';
import { EnterpriseAttachmentService } from '../components/form/services/enterprise-attachment.service';
import { IEnterpriseRankingUsers } from '../components/form/interface/enterprise-ranking-users.interface';
import { EnterpriseRankingUsers } from './enterprise-ranking-users.entity';

export class Enterprise {
  public id: string = '';

  public name: string = '';

  public address: Address = new Address();

  public attachments: Attachment[] = [];

  public registration: string = '';

  public city_state: string = '';

  public has_relationship: boolean | number = false;

  public finished_at: string = '';

  public building_area: string = '';

  public users: User[] = [];

  public payment_conditions: PaymentCondition[] = [];

  public total_area: string = '';

  public total_units: string = '';

  public company_id: string = '';

  public code: string = '';

  public image: string = '';

  public blocks: Block = new Block(0);

  public active: boolean | number = false;

  public units: Unit[] = [];

  public notes: string = '';

  public results: EnterpriseResults;

  public ranking_users: IEnterpriseRankingUsers;

  constructor(data: any = {}) {
    this.id = data.id || null;
    this.name = data.name || '';
    this.address = new Address(data.address);
    this.blocks = data.blocks ? new Block(data.blocks.length) : new Block(1);
    this.attachments = data.attachments || [];
    this.registration = data.registration || '';
    this.finished_at = data.finished_at || moment().format('YYYY-MM-DD');
    this.building_area = data.building_area || '';
    this.total_area = data.total_area || '';
    this.company_id = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.active = data.active === false ? 0 : 1;
    this.total_units = data.total_units || '';
    this.code = data.code || '';
    this.city_state = data.city_state || '';
    this.units = data.units ? data.units.map((it: any) => new Unit(it)) : [];
    this.notes = data.notes || '';
    this.users = data.users || [];
    this.payment_conditions = data.payment_conditions || [];
    this.image = data.image || '/img/defaults/enterprise-default.jpg';
    this.has_relationship = data.has_relationship === true;
    this.results = new EnterpriseResults(data.results);
    this.ranking_users = new EnterpriseRankingUsers(data.ranking_users);
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  public get banner() {
    const data = this.attachments.find(
      (it: Attachment) => it.type === EnterpriseAttachmentEnum.PRINCIPAL,
    );
    return data ? data.file : '';
  }

  private format() {
    return {
      ...this,
      finished_at: moment(this.finished_at).format('YYYY-MM-DD'),
      address: this.address.formatToSave(),
      users: this.users.map((it: User) => it.id),
      payment_conditions: this.payment_conditions.map((it: PaymentCondition) => it.id),
      active: this.active ? 1 : 0,
      building_area: this.building_area,
      total_area: this.total_area,
      blocks: this.blocks.formatToSend(),
      units: undefined,
    };
  }

  public async removeTemporaryUnit(unit: Unit) {
    const index = this.units.findIndex(it => it.temporaryId === unit.temporaryId);
    this.units.splice(index, 1);
    return true;
  }

  public setBanner(attachment: IAttachmentDefault) {
    const attachmentToSend = new AttachmentDefault(attachment);
    const enterpriseAttachmentService = new EnterpriseAttachmentService(
      this.id,
      EnterpriseAttachmentEnum.PRINCIPAL,
    );

    return enterpriseAttachmentService.send(attachmentToSend);

    // const index = this.attachments.findIndex(
    //   (it: Attachment) => it.type === EnterpriseAttachmentEnum.PRINCIPAL,
    // );
    // if (index >= 0) {
    //   this.attachments.splice(index, 1, {
    //     file: formData,
    //     name: 'Banner',
    //     id: undefined,
    //     path: '',
    //     type: EnterpriseAttachmentEnum.PRINCIPAL,
    //   });
    //   return;
    // }
    // this.attachments.push({
    //   file: formData,
    //   name: 'Banner',
    //   id: undefined,
    //   path: '',
    //   type: EnterpriseAttachmentEnum.PRINCIPAL,
    // });
  }

  public save(): Promise<any> {
    return enterpriseService.create(this.format());
  }

  public update() {
    return enterpriseService.update(this.format());
  }

  public delete() {
    return enterpriseService.delete(this.id);
  }
}
