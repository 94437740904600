import { IEnterpriseRankingCurrency } from '../components/form/interface/enterprise-ranking-users.interface';
import { EnterpriseRankingDefault } from './enterprise-ranking-default.entity';

export class EnterpriseRankingCurrency extends EnterpriseRankingDefault
  implements IEnterpriseRankingCurrency {
  constructor(data: any = {}) {
    super(data);
  }

  public get valueFormatted() {
    return this.value
      ? Number(this.value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      : '';
  }
}
