import Attachment from '@/modules/shared/entities/attachment.entity';
import AttachmentDefault from '@/shared-modules/attachments-default/entities/attachment-default.entity';
import { IAttachmentDefault } from '@/shared-modules/attachments-default/interfaces/attachment-default.interface';

import { AbstractAttachmentDefaultService } from '@/shared-modules/attachments-default/services/abstract-attachment-default.service';

import { EnterpriseAttachmentEnum } from '../enums/enterprise-attachment.enum';
import { IEnterpriseAttachmentService } from '../interface/enterprise-attachment-service.interface';

export class EnterpriseAttachmentService extends AbstractAttachmentDefaultService
  implements IEnterpriseAttachmentService {
  private type: EnterpriseAttachmentEnum;

  private enterpriseId: string;

  constructor(enterpriseId: string, type: EnterpriseAttachmentEnum) {
    super();
    this.enterpriseId = enterpriseId;
    this.type = type;
  }

  public createUrl() {
    return `/enterprise/${this.enterpriseId}/attachment/${this.type}`;
  }

  public formatAttachments(attachments: Attachment[]) {
    return attachments
      .map((it: Attachment) => new AttachmentDefault(it))
      .filter((it: IAttachmentDefault) => it.type === this.type);
  }
}
