import {
  IEnterpriseRankingCurrency,
  IEnterpriseRankingDefault,
  IEnterpriseRankingUsers,
} from '../components/form/interface/enterprise-ranking-users.interface';
import { EnterpriseRankingCurrency } from './enterprise-ranking-currency.entity';
import { EnterpriseRankingDefault } from './enterprise-ranking-default.entity';

export class EnterpriseRankingUsers implements IEnterpriseRankingUsers {
  public count: IEnterpriseRankingDefault;

  public currency: IEnterpriseRankingCurrency;

  constructor(data: any = {}) {
    this.count = new EnterpriseRankingDefault(data.count);
    this.currency = new EnterpriseRankingCurrency(data.currency);
  }
}
