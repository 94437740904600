import { IAttachmentDefault } from '../interfaces/attachment-default.interface';

export default class AttachmentDefault implements IAttachmentDefault {
  public id: string;

  public file: File[];

  public name: string;

  public path: string;

  public type: string;

  public description: string;

  constructor(
    data: IAttachmentDefault | any = {
      id: '',
      name: '',
      path: '',
      type: '',
      description: '',
    },
  ) {
    this.id = data.id;
    this.name = data.name;
    this.type = data.type;
    this.description = data.description;
    this.path = data.path || '';
    this.file = data.file || [];
  }

  private getFormDataFile() {
    if (this.file && this.file.length) {
      return this.file[0];
    }
    return '';
  }

  public formatToSend() {
    return Object.keys(this).reduce((formData: FormData, value: string) => {
      const context: any = this;
      if (value === 'file') {
        formData.append(value, this.getFormDataFile());
        return formData;
      }
      if (context[value]) {
        formData.append(value, context[value]);
      }
      return formData;
    }, new FormData());
  }
}
