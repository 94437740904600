import http from '@/services/http.service';
import { IAttachmentDefaultService } from '../interfaces/abstract-attachment-default.service';
import { IAttachmentDefault } from '../interfaces/attachment-default.interface';

export abstract class AbstractAttachmentDefaultService implements IAttachmentDefaultService {
  abstract createUrl(): string;

  public async send(attachment: IAttachmentDefault): Promise<void> {
    const url = this.createUrl();
    return http.post(url, attachment.formatToSend()).then(({ data }) => data);
  }

  public async remove(id: string): Promise<void> {
    return http.delete(`/attachment/${id}`).then(({ data }) => data);
  }
}
