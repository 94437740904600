export class EnterpriseResults {
  public blocks: string;

  public units: string;

  public sold: string;

  public reserved: string;

  public distract: string;

  public blocked: string;

  public available: string;

  public conversion: string;

  public vgv_sold: string;

  public vgv_total: string;

  constructor(data: any = {}) {
    this.blocks = data.blocks || 0;
    this.units = data.units || 0;
    this.sold = data.sold || 0;
    this.reserved = data.reserved || 0;
    this.distract = data.distract || 0;
    this.blocked = data.blocked || 0;
    this.available = data.available || 0;
    this.conversion = data.conversion || 0;
    this.vgv_sold = data.vgv_sold || 0;
    this.vgv_total = data.vgv_total || 0;
  }

  public get vgvSold() {
    return this.vgv_sold
      ? Number(this.vgv_sold).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      : 'R$ 0,00';
  }

  public get vgvTotal() {
    return this.vgv_total
      ? Number(this.vgv_total).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
      : 'R$ 0,00';
  }
}
