import { IBlock, IBlocksContentForm } from '../interface/block.interface';

export class Block implements IBlock {
  public qtyBlocks: number = 0;

  private contentBlocks: IBlocksContentForm[] = [];

  constructor(quantity: number) {
    this.qtyBlocks = quantity || 1;
  }

  public formatToSend() {
    return this.contentBlocks.map(it => it.quantity);
  }

  public generateBlocks() {
    for (let index = 0; index < this.qtyBlocks; index += 1) {
      this.contentBlocks.push({ id: index, quantity: 1 });
    }
    return this.contentBlocks;
  }

  public setContentBlocks(blocks: IBlocksContentForm[]) {
    this.contentBlocks = blocks;
  }
}
