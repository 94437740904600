import { AxiosResponse } from 'axios';
import http from '@/services/http.service';
import {
  ISimplePaginationService,
  SimplePagination,
} from '@/entities/pagination/simple-pagination';
import { PaymentCondition } from '../entities/payment-condition.entity';

class PaymentConditionService {
  public simplePaginationService: ISimplePaginationService;

  constructor(simplePaginationService: ISimplePaginationService) {
    this.simplePaginationService = simplePaginationService;
  }

  public create(payload: PaymentCondition): Promise<any> {
    return http.post('/payment-condition', payload).then(({ data }: AxiosResponse) => data);
  }

  public update(payload: PaymentCondition) {
    return http
      .put(`/payment-condition/${payload.id}`, payload)
      .then(({ data }: AxiosResponse) => data);
  }

  public delete(paymentConditionId: string) {
    return http
      .delete(`/payment-condition/${paymentConditionId}`)
      .then(({ data }: AxiosResponse) => data);
  }

  public getAllPayments(payload: any = {}) {
    return http.get('/payment-condition', payload).then(({ data }: AxiosResponse) => data.data);
  }

  public getAllPaymentsPaginated(payload: any = {}) {
    return http
      .get('/payment-condition', {
        params: {
          ...payload,
          paginate: 1,
        },
      })
      .then(({ data }: AxiosResponse) => {
        this.simplePaginationService.setPaginationLinks(data.links);
        return data.data;
      });
  }

  public getPaymentsByOrderId(orderId: string = '') {
    return http
      .get(`/order/${orderId}/payment-condition`)
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getPaymentsByEnterpriseId(enterprise_id: string = '') {
    return http
      .get('/payment-condition', { params: { enterprise_id, active: 1 } })
      .then(({ data }: AxiosResponse) => data.data);
  }

  public getPaymentConditionById(id: string) {
    return http.get(`/payment-condition/${id}`).then(({ data }: AxiosResponse) => data.data);
  }
}

const simplePaginationService = new SimplePagination();
export default new PaymentConditionService(simplePaginationService);
