import store from '@/store';
import { Enterprise } from '../../Enterprises/entities/enterprise.entity';
import PaymentConditionService from '../services/payment-condition.service';

export class PaymentCondition {
  public id: string;

  public description: string;

  public active: boolean | number;

  public limit_installments_qty: number;

  public enterprises: Enterprise[] = [];

  public companyId: string = '';

  public company: any;

  public interest_rate: string = '';

  public has_relationship: boolean | number = false;

  constructor(data: any = {}) {
    this.id = data.id;
    this.description = data.description || '';
    this.limit_installments_qty = data.limit_installments_qty;
    this.interest_rate = data.interest_rate || 0;
    this.active = data.active === false ? 0 : 1;
    this.companyId = data.company && data.company.id ? data.company.id : store.getters.user.company_id;
    this.company = data.company;
    this.enterprises = data.enterprises || [];
    this.has_relationship = data.has_relationship === true;
  }

  public get activeText() {
    return this.active ? 'Ativo' : 'Inativo';
  }

  public get interestRateFormatted() {
    return `${this.interest_rate}%`;
  }

  private format() {
    return {
      ...this,
      company_id: this.companyId,
      enterprises: this.enterprises.map((it: Enterprise) => it.id),
      interest_rate: this.interest_rate ? Number(this.interest_rate) : undefined,
    };
  }

  public save(): Promise<any> {
    return PaymentConditionService.create(this.format());
  }

  public update() {
    return PaymentConditionService.update(this.format());
  }

  public delete() {
    return PaymentConditionService.delete(this.id);
  }
}
